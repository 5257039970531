import React from 'react'

const Footeradmin = () => {
  return (
  
    <footer className="main-footer">
    <div className="pull-right hidden-xs">
      <b>Version</b> 0.1.0
    </div>
    <strong>Copyright &copy; 2018 - 2024.</strong> All rights
    reserved.
  </footer>

  )
}

export default Footeradmin